class Flat {
    constructor(name, numRooms, sizePrivate, isWbs = false, isAccessible = false, wgRoomList = null) {
        this.name = name;
        this.numRooms = numRooms;
        this.sizePrivate = sizePrivate;
        this.isWbs = isWbs;
        this.sizeShared = 0;
        // Normal flats have as many people as rooms
        // WGs and cluster flats have one more room
        if (!wgRoomList) {
            this.wgRoomList = [];
            this.numPeople = numRooms;
        } else {
            this.wgRoomList = wgRoomList;
            this.sizeShared = this.sizePrivate;
            for (var i = 0; i < this.wgRoomList.length; i++) {
                this.sizeShared -= this.wgRoomList[i].size;
            }
            this.numPeople = numRooms - 1;
        }

    }

    // Returns a string representation of the flat
    print() {
        return this.name + " (" + this.numRooms + " Zimmer)";
    }

    // Calculates the share of the internal shared area the party has to pay for
    // This is only applicable for WGs and clusters, i.e. when a room is selected.
    calcInternalAreaShare(room = null) {
        return room ? this.sizeShared / this.numPeople * room.numPeople : 0;
    }

    // Calculates the share of the project wide common area
    // the party has to pay for.
    // Currently done by number of people in the party.
    calcCommonAreaShare(room = null) {
        var numPeople;
        if (room) {
            numPeople = room.numPeople;
        } else {
            numPeople = this.numRooms;
        }

        return commonArea / numPeopleTotal * numPeople;
    }

    // Calculates the total area the party has to pay for
    // Includes the private area, share of the common area and for
    // WGs and clusters the share of the internal shared area
    calcTotalPayedArea(room = null) {
        var commonAreaShare = this.calcCommonAreaShare(room);

        if (!room) {
            return this.sizePrivate + commonAreaShare;
        } else {
            return room.size + commonAreaShare + this.calcInternalAreaShare(room);
        }
    }
}

class Room {
    constructor(name, size, numPeople = 1) {
        this.name = name;
        this.size = size;
        this.numPeople = numPeople;
    }

    print() {
        return this.name;
    }
}

export const flatData = {
    "north": {
        0: [
            new Flat("W 0.1", 4, 82.7, false, false, [
                new Room("0.1 02", 12.8), new Room("0.1 03", 12.8), new Room("0.1 04", 14.6)
            ]),
            new Flat("W 0.2", 2, 53.1, true),
            new Flat("W 0.3", 3, 62, true)
        ],
        1: [
            new Flat("W 1.1", 4, 82.5, true),
            new Flat("W 1.2", 1, 35.4, true),
            new Flat("W 1.3", 4, 61, true, false, [
                new Room("1.3 02", 11.88), new Room("1.3 03", 11.41), new Room("1.3 04", 11.89)
            ])
        ],
        2: [
            new Flat("W 2.1", 4, 82.6, true),
            new Flat("W 2.2", 2, 45.3, true),
            new Flat("W 2.3", 5, 148.77, true, true, [
                new Room("1 Person", 25.32), new Room("2 Personen", 44.52, 2), new Room("1 Person (rollstuhlgerecht)", 39.8)
            ]),
            new Flat("W 2.4", 2, 62.5, true, true)
        ],
        3: [
            new Flat("W 3.1", 4, 82.6),
            new Flat("W 3.2", 5, 148.77, true, true, [
                new Room("1 Person", 25.32), new Room("2 Personen", 44.52, 2), new Room("1 Person (rollstuhlgerecht)", 39.8)
            ]),
            new Flat("W 3.3", 3, 61.8, true)
        ]
    },
    "south": {
        0: [
            new Flat("W 0.4", 2, 55.9, true),
            new Flat("W 0.5", 4, 84.6, true),
            new Flat("W 0.6", 5, 95.8, true),
        ],
        1: [
            new Flat("W 1.4", 2, 55.7),
            new Flat("W 1.5 (WG)", 5, 83.86, true, false,
                [new Room("1.5 02", 15.72), new Room("1.5 03", 9.81), new Room("1.5 05", 11.48), new Room("1.5 06", 13.45)]
            ),
            new Flat("W 1.6.0", 1, 25.6),
            new Flat("W 1.6", 5, 95.7),
        ],
        2: [
            new Flat("W 2.5", 2, 55.8),
            new Flat("W 2.6", 4, 84.6, true),
            new Flat("W 2.7", 5, 95.7)
        ]
    }
}

export const minRent = 9.50;
export const maxRent = 15.60;

const commonArea = 388.80;

// Calculate total number of people
var _numPeople = 0;

for (const building of Object.keys(flatData)) {
    for (const floor of Object.keys(flatData[building])) {
        for (const flat of flatData[building][floor]) {
            _numPeople += flat.numPeople;
        }
    }
}

const numPeopleTotal = _numPeople;