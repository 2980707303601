import { useState } from 'react';
import {
    Card,
    CardHeader,
    Heading,
    CardBody,
    Stack,
    StackDivider,
    Box,
    Text,
    Badge,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    Select,
    Progress,
    NumberInput,
    NumberInputField,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderTrack,
    SliderThumb,
    Tooltip
} from '@chakra-ui/react';

import { flatData, minRent, maxRent } from './Data';

export function FlatSelectionCard({ setSelectedFlat, onRoomSelected }) {
    const [levelOptions, setLevelOption] = useState([{ value: 0, label: "EG" }, { value: 1, label: "1. OG" }, { value: 2, label: "2. OG" }, { value: 3, label: "3. OG" }]);
    const [flatOptions, setFlatOptions] = useState([]);
    const [roomOptions, setRoomOptions] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState();

    function buildingSelected(e) {
        var newOptions;
        if (e.target.value === "south") {
            newOptions = [{ value: 0, label: "EG" }, { value: 1, label: "1. OG" }, { value: 2, label: "2. OG" }];
        } else {
            newOptions = [{ value: 0, label: "EG" }, { value: 1, label: "1. OG" }, { value: 2, label: "2. OG" }, { value: 3, label: "3. OG" }];
        }
        setLevelOption(newOptions);
        setSelectedBuilding(e.target.value);
    };

    function levelSelected(e) {
        var newData = flatData[selectedBuilding][e.target.value];
        if (newData) {
            setFlatOptions(flatData[selectedBuilding][e.target.value]);
        }
    }

    function flatSelected(e) {
        var index = e.target.value;
        var flat = flatOptions[index];
        if (flat.wgRoomList) {
            setRoomOptions(flat.wgRoomList);
        }
        setSelectedFlat(flatOptions[index]);
        onRoomSelected(null);
    }
    function roomSelected(e) {
        onRoomSelected(roomOptions[e.target.value]);
    }

    return <Card>
        <CardHeader>
            <Heading size='md'>Wohnungsauswahl</Heading>
        </CardHeader>
        <CardBody>
            <Stack spacing={4} >
                <Select placeholder='Gebäude auswählen' onChange={buildingSelected}>
                    <option value='north'>Nordbau</option>
                    <option value='south'>Südbau</option>
                </Select>
                <Select placeholder='Etage auswählen' onChange={levelSelected}>
                    {levelOptions.map(({ value, label }, index) => <option value={value} >{label}</option>)}
                </Select>
                <Select placeholder='Wohnung auswählen' onChange={flatSelected}>
                    {flatOptions.map((flat, index) => <option value={index} >{flat.print()}</option>)}
                </Select>
                {roomOptions.length > 0 ? (<Select placeholder='Zimmer auswählen' onChange={roomSelected}>
                    {roomOptions.map((room, index) => <option value={index} >{room.print()} ({room.size} m²)</option>)}
                </Select>) : null}
            </Stack>
        </CardBody>
    </Card>
}

export function FlatDetailsCard({ flat, room }) {
    var body = <CardBody>
        Bitte auswählen
    </CardBody>;

    var badges = "";

    if (flat && (room || flat.wgRoomList.length === 0)) {
        var sizePrivate = flat.sizePrivate;
        if (room) {
            sizePrivate = room.size;
        }

        var sizeSharedBox = null;

        var commonAreaShare = flat.calcCommonAreaShare(room);
        var internalAreaShare = flat.calcInternalAreaShare(room);

        if (flat.wgRoomList.length > 0) {

            sizeSharedBox = <Box>
                <Heading size='xs' textTransform='uppercase'>
                    Interne Gemeinschaftsfläche
                </Heading>
                <Text pt='2' fontSize='sm'>
                    {flat.sizeShared.toFixed(2)} m²
                </Text>
            </Box>;

        }

        var totalSizeToPay = flat.calcTotalPayedArea(room);

        body = <CardBody>

            <Stack divider={<StackDivider />} spacing='4'>

                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Bezeichnung
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        {flat.name} {room ? "Zimmer: " + room.print() : null}
                    </Text>
                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Zimmeranzahl
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        {flat.numRooms}
                    </Text>
                </Box>
                {sizeSharedBox}
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Anteil Gemeinschaftsfläche
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        {commonAreaShare.toFixed(2)} m² {internalAreaShare ? ("(Allmendefläche) + " + internalAreaShare.toFixed(2) + " m² (WG/Cluster-Fläche)") : ""}
                    </Text>
                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                        Zahlfläche
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                        {sizePrivate.toFixed(2)} m² + {commonAreaShare.toFixed(2)} m² (Allmendefläche) {internalAreaShare ? " + " + (internalAreaShare.toFixed(2) + " m² (WG/Cluster-Fläche)") : null}
                    </Text>
                    <Text pt={3} size='s' fontWeight='bold'>Gesamt: {totalSizeToPay.toFixed(2)} m² </Text>
                </Box>
            </Stack>
        </CardBody>;

        if (flat.isWbs) {
            badges = <Badge colorScheme='blue'>
                mit WBS
            </Badge>;
        } else {
            badges = <Badge colorScheme='yellow'>
                ohne WBS
            </Badge>;
        }
    }

    return (
        <Card>

            <CardHeader>
                {badges}
                <Heading size='md'>Wohnungsdetails</Heading>
            </CardHeader>
            {body}
        </Card>
    );
}

export function SelfEvaluationCard({ rent, setRent }) {

    const [sliderValue, setSliderValue] = useState(30)
    const [showTooltip, setShowTooltip] = useState(false)

    const [income, setIncome] = useState(1000)

    const formatEuro = (val) => val + ' €';
    const parseEuro = (val) => val.replace(/^€/, '')


    function onIncomeChange(valueString) {
        setIncome(parseEuro(valueString));
        setRent(valueString * sliderValue / 100);
    }

    function onSliderChange(value) {
        setSliderValue(value);
        setRent(income * value / 100);
    }

    return <Card>
        <CardBody maxW={500}>
            <Stack spacing={3}>
                <Text>Wie hoch ist euer/dein Haushaltsnetto? Beachtet dabei alle regelmäßigen Einkünfte wie Gehalt, Rente, Mieteinkünfte oder Kapitalerträge </Text>
                <NumberInput
                    onChange={onIncomeChange}
                    value={formatEuro(income)}
                >
                    <NumberInputField />
                </NumberInput>

                <Text>Wie viel Prozent eures/deines Nettoeinkommens kannst du für Miete aufbringen?</Text>
                <Slider
                    id='slider'
                    defaultValue={30}
                    min={10}
                    max={50}
                    mb={5}
                    colorScheme='teal'
                    onChange={onSliderChange}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <SliderMark value={10} mt='1' ml='-2.5' fontSize='sm'>
                        10%
                    </SliderMark>
                    <SliderMark value={20} mt='1' ml='-2.5' fontSize='sm'>
                        20%
                    </SliderMark>
                    <SliderMark value={30} mt='1' ml='-2.5' fontSize='sm'>
                        30%
                    </SliderMark>
                    <SliderMark value={40} mt='1' ml='-2.5' fontSize='sm'>
                        40%
                    </SliderMark>
                    <SliderMark value={50} mt='1' ml='-2.5' fontSize='sm'>
                        50%
                    </SliderMark>
                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <Tooltip
                        hasArrow
                        bg='teal.500'
                        color='white'
                        placement='top'
                        isOpen={showTooltip}
                        label={`${sliderValue}%`}
                    >
                        <SliderThumb />
                    </Tooltip>


                </Slider>
            </Stack>
        </CardBody>
    </Card>
}

export function ResultsCard({ rent, flat, room }) {
    var body = <CardBody></CardBody>
    if (flat && (room || flat.wgRoomList.length === 0)) {
        var totalSize = flat.calcTotalPayedArea(room)

        var relativeRent = rent / totalSize;
        var rangePosition = ((relativeRent - minRent) / (maxRent - minRent)) * 100;
        if (rangePosition < 0) { rangePosition = 0; }
        if (rangePosition > 100) { rangePosition = 100; }
        var color = "green";
        if (rangePosition > 33) { color = "yellow"; }
        if (rangePosition > 66) { color = "red"; }
        body = <CardBody>
            <Stack>
                <StatGroup>
                    <Stat>
                        <StatLabel>Miete</StatLabel>
                        <StatNumber>{rent} €</StatNumber>
                    </Stat>

                    <Stat>
                        <StatLabel>Pro m²</StatLabel>
                        <StatNumber>{relativeRent.toFixed(2)} € </StatNumber>
                    </Stat>
                </StatGroup>
                <Text>
                    Im Folgenden siehst du, wo deine Miete auf der Spanne zwischen WBS-Satz und
                    frei finanzierten Wohnungen ohne Soli-Ausgleich liegen würde.
                </Text>
                <Progress colorScheme={color} size='sm' value={rangePosition} />
                <StatGroup>
                    <Stat>
                        <StatLabel>WBS Miete</StatLabel>
                        <StatNumber>{minRent.toFixed(2)} €</StatNumber>
                    </Stat>

                    <Stat>
                        <StatLabel>Max</StatLabel>
                        <StatNumber>{maxRent.toFixed(2)} € </StatNumber>
                    </Stat>
                </StatGroup>
            </Stack>
        </CardBody>;
    }

    return <Card>
        {body}
    </Card>
}